import React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import stTags from '../../components/WithStyledTags/withStyledTags.module.scss';
import st from './privacyPolicy.module.scss';
import classNames from 'classnames';

const PrivacyPolicy = () => {
    const intl = useIntl();

    function formatHumanDate(date) {
        const day = intl.formatDate(new Date(date), { day: 'numeric' });
        const month = intl.formatDate(new Date(date), { month: 'long' });
        const year = intl.formatDate(new Date(date), { year: 'numeric' });

        return `${month} ${day}, ${year}`;
    }

    return (
        <section className={classNames(stTags.with_styled_tags, st.main)}>
            <div className={st.hero_container}>
                <h1>{intl.formatMessage({ id: 'privacyPolicy.title' })}</h1>

                <div className={st.date}>{formatHumanDate(new Date())}</div>
            </div>

            <div className={st.main_container}>
                <h2 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'privacyPolicy.content.title' }) }}></h2>
                <h3>Personal Data</h3>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <a href="#/">eiusmod tempor incididunt</a> ut labore et dolore
                    magna aliqua. Mi sit amet mauris commodo quis imperdiet massa tincidunt. Enim blandit volutpat maecenas volutpat blandit aliquam
                    etiam erat velit. Viverra nibh cras pulvinar mattis nunc sed. Lorem donec massa sapien faucibus et molestie ac feugiat. Id cursus
                    metus aliquam eleifend. Senectus et netus et malesuada fames ac turpis egestas. Dictum fusce ut placerat orci nulla pellentesque
                    dignissim. Mauris cursus mattis molestie a iaculis at erat. Vel facilisis volutpat est velit.
                </p>
                <p>
                    Amet risus nullam eget felis eget nunc. Neque vitae tempus quam pellentesque nec. Tincidunt lobortis feugiat vivamus at augue eget
                    arcu. Porttitor lacus luctus accumsan tortor posuere ac ut. Volutpat sed cras ornare arcu dui vivamus arcu felis. Auctor augue
                    mauris augue neque gravida. Tristique senectus et netus et malesuada. Morbi tristique senectus et netus. Libero id faucibus nisl
                    tincidunt eget nullam non nisi. Sit amet commodo nulla facilisi nullam. Ullamcorper dignissim cras tincidunt lobortis feugiat
                    vivamus at augue eget.
                </p>
                <br />
                <h3>Personal Data</h3>
                <h4>Consumer Personal Data</h4>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mi sit
                    amet mauris commodo quis imperdiet massa tincidunt. Enim blandit volutpat maecenas volutpat blandit aliquam etiam erat velit.
                    Viverra nibh cras pulvinar mattis nunc sed. Lorem donec massa sapien faucibus et molestie ac feugiat. Id cursus metus aliquam
                    eleifend. Senectus et netus et malesuada fames ac turpis egestas. Dictum fusce ut placerat orci nulla pellentesque dignissim.
                    Mauris cursus mattis molestie a iaculis at erat. Vel facilisis volutpat est velit.
                </p>
                <br />
                <h5>Categories of Personal Data We Collect</h5>
                <ol>
                    <li>Payment Information</li>
                    <li>Device/IP Information</li>
                    <li>Web Analytics</li>
                    <li>Geolocation Data</li>
                    <li>Consumer Contact Data</li>
                </ol>
                <br />
                <h5>Client Personal Data</h5>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mi sit
                    amet mauris commodo quis imperdiet massa tincidunt. Enim blandit volutpat maecenas volutpat blandit aliquam etiam erat velit.
                    Viverra nibh cras pulvinar mattis nunc sed. Lorem donec massa sapien faucibus et molestie ac feugiat. Id cursus metus aliquam
                    eleifend. Senectus et netus et malesuada fames ac turpis egestas. Dictum fusce ut placerat orci nulla pellentesque dignissim.
                    Mauris cursus mattis molestie a iaculis at erat. Vel facilisis volutpat est velit.
                </p>
                <p>
                    Amet risus nullam eget felis eget nunc. Neque vitae tempus quam pellentesque nec. Tincidunt lobortis feugiat vivamus at augue eget
                    arcu. Porttitor lacus luctus accumsan tortor posuere ac ut. Volutpat sed cras ornare arcu dui vivamus arcu felis. Auctor augue
                    mauris augue neque gravida. Tristique senectus et netus et malesuada. Morbi tristique senectus et netus. Libero id faucibus nisl
                    tincidunt eget nullam non nisi. Sit amet commodo nulla facilisi nullam. Ullamcorper dignissim cras tincidunt lobortis feugiat
                    vivamus at augue eget.
                </p>
                <p>Categories of Personal Data We Collect:</p>
                <ul>
                    <li>Payment Information</li>
                    <li>Device/IP Information</li>
                    <li>Web Analytics</li>
                    <li>Geolocation Data</li>
                    <li>Consumer Contact Data</li>
                </ul>
            </div>
            <div className={st.table}>
                <table>
                    <thead>
                        <tr>
                            <th>Level</th>
                            <th>Option 1</th>
                            <th>Option 2</th>
                            <th>Option 3</th>
                            <th>Option 4</th>
                            <th>Option 5</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Level 1</td>
                            <td>Position 1</td>
                            <td>Position 2</td>
                            <td>Position 3</td>
                            <td>Position 4</td>
                            <td>Position 5</td>
                        </tr>
                        <tr>
                            <td>Level 2</td>
                            <td>Position 1.2</td>
                            <td>Position 2.2</td>
                            <td>Position 3.2</td>
                            <td>Position 4.2</td>
                            <td>Position 5.2</td>
                        </tr>
                        <tr>
                            <td>Level 3</td>
                            <td>Position 1.3</td>
                            <td>Position 2.3</td>
                            <td>Position 3.3</td>
                            <td>Position 4.3</td>
                            <td>Position 5.3</td>
                        </tr>
                        <tr>
                            <td>Level 4</td>
                            <td>Position 1.4</td>
                            <td>Position 2.4</td>
                            <td>Position 3.4</td>
                            <td>Position 4.4</td>
                            <td>Position 5.4</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default observer(PrivacyPolicy);
